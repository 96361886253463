<template>
  <div id="card-subject-detail">
    <div class="subject-info-course" v-for="(item, index) in dtf" :key="index">
      <div class="is-flex js-between">
        <p class="font-12 text-main">{{ item.detail.namecourse }}</p>
        <p class="font-12 text-main mg-b-5">
          <i class="el-icon-arrow-right"></i>
        </p>
      </div>

      <div class="is-flex js-between ">
        <div class="is-flex">
          <div class="status"></div>
          <p class="font-12 mg-l-5 mg-t-0">
            {{ item.detail.detailcourse }}
          </p>
        </div>

        <div>
          <p class="font-12  mg-t-0 text-green">
            {{ item.detail.learninghour + " Learning hour" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["dtf"],
};
</script>

<style></style>
