<template>
  <div id="history-subject">
    <defaultTemplate>
      <div class="header-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/badges-history' }"
            >Badges history</el-breadcrumb-item
          >
          <el-breadcrumb-item>Semester 1/2020</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="title mg-b-3 mg-t-3">
        {{ Subject.subject }}
      </div>

      <carousel :responsive="responsive" :nav="false">
        <div class="card mg-6" v-for="(item, index) in 3" :key="index">
          <h4 class="mg-0 font-15 font-weight-500">DTF.1 (Fundamental)</h4>
          <p class="mg-0 font-10 mg-t-6 ">Self-learning</p>
        </div>
      </carousel>

      <div class="badges-subject-title is-flex js-between">
        <p class="font-14 text-main font-weight-500">
          {{ Subject.subject }}
        </p>
        <p class="font-12 font-weight-400 text-main mg-b-5">
          {{ Subject.badgesitem + " Badges" }}
        </p>
      </div>
      <div v-for="(i, index) in 3" :key="index">
        <div class="card  mg-t-0 mg-x-5 mg-b-5 is-flex js-between ai-center">
          <div>
            <p class="font-12 font-weight-400">
              "Agile for Business Transformation-Agile in Education"
            </p>
            <p class="font-10">3 Learning hour</p>
          </div>
          <div>
            <i class="el-icon-arrow-right "></i>
          </div>
        </div>
      </div>
      <!-- collapse -->
      <!-- <div class="collapse-hb">
        <el-collapse v-model="activeName" accordion>
          <div v-for="(dtf, index) in Subject.dtf" :key="index">
            <el-collapse-item :name="dtf.dtfid">
              <template slot="title">
                <div class="is-flex  js-between header-title">
                  <p class="font-14 text-main">{{ dtf.dtfname }}</p>
                  <p class="font-10 text-main">
                    {{ dtf.dtfbadges + " Badges" }}
                  </p>
                </div>
              </template>
              <div>
                <div
                  class="subject-info-course"
                  v-for="(item, index) in dtf.info"
                  :key="index"
                  @click="changePage(item.certificate, item.id)"
                >
                  <div class="is-flex js-between">
                    <p class="font-12 text-main">
                      {{ item.detail.namecourse }}
                    </p>
                    <p class="font-12 text-main">
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>

                  <div class="is-flex js-between ">
                    <div class="is-flex">
                      <div class="status"></div>
                      <p class="font-12 mg-l-5 mg-t-0">
                        <i class="fas fa-circle font-10 text-green"></i>
                        {{ item.detail.detailcourse }}
                      </p>
                    </div>

                    <div>
                      <p class="font-12  mg-t-0 text-green">
                        {{ item.detail.learninghour + " Learning hour" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div> -->
    </defaultTemplate>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

import CardSubjectDetail from "@/components/BadgesHistory/CardSubjectDetail.vue";
import defaultTemplate from "@/template/default.vue";
export default {
  components: {
    defaultTemplate,
    CardSubjectDetail,
    carousel,
  },

  mounted() {
    this.getSubject();
  },
  data() {
    return {
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2,
          loop: true,
          // option1 : value,
          // option2 : value,
        },
        // breakpoint from 768 up
        767: {
          items: 2,
        },
      },
      activeName: "1",
      SubjectId: this.$route.params.id,
      Subject: [],
      toggleid: [],
      BadgesSubject: [
        {
          id: 1,
          subject: "Digital Transformation",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 1,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              certificate: false,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  detail: {
                    namecourse: "Agile for Business Transformation 01",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management 02",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management 03",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation 00",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management 09",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management 08",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 3,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation 11",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management 12",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management 13",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 2,
          subject: "Project and Change Management",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 43,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 3,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 3,
          subject: "Data Process",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 23,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 4,
          subject: "Select Topics for TM",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 431,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 5,
          subject: "Select Topics for DSP",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 461,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 6,
          subject: "Select Topics for DW",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 109,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 7,
          subject: "Select Topics for PA",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 7621,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 8,
          subject: "Ethics",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 31,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: true,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
        {
          id: 9,
          subject: "Problem Based Learning",
          badgesitem: 12,
          total: 3,
          dtf: [
            {
              dtfid: 129,
              dtfname: "DTF. 1",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 103,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 2,
              dtfname: "DTF. 2",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
            {
              dtfid: 1,
              dtfname: "DTF. 3",
              dtfbadges: 3,
              info: [
                {
                  id: 101,
                  name: "1",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "Agile for Business Transformation",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "2",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
                {
                  id: 102,
                  name: "3",
                  badges: 6,
                  certificate: false,
                  detail: {
                    namecourse: "The Future of Performance Management",
                    detailcourse: "Online Seminar",
                    learninghour: 1,
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    getSubject() {
      console.log(this.$route.params.id);
      let subjectitem = this.BadgesSubject.map((item) => {
        // return item;
        if (this.$route.params.id == item.id) {
          this.Subject = item;
        }
      });
    },

    changePage(status, id) {
      if (status) {
        this.$router.push(`/subject/certificate/` + id);
      }
    },
  },
};
</script>

<style></style>
